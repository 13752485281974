import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import './index.css'
// import OldNE from '../images/fyling-horse.jpg'
import CommonHero from '../components/commonhero/commonhero.js'
import _ from 'lodash'
import Swiper from 'react-id-swiper'
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm'
// import 'react-id-swiper/lib/styles/css/swiper.css'
import './fall-round-up.css'
let banner = require('../data/fun.json')

class FallPage extends React.Component {
  render() {
    const banners = banner.filter(c => {
      return _.sortBy(c.id)
    })
    const slideshowParams = {
      modules: [Navigation],
      spaceBetween: 0,
      containerClass: 'swiper-container',
      nested: true,
      autoplay: {
        delay: 5000,
      },
    }
    const feedbackParams = {
      modules: [Pagination],
      spaceBetween: 0,
      autoHeight: false,
      containerClass: 'swiper-container pb-5',
      nested: true,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    }
    return (
      <Layout head={{ title: 'Downtown Colorado Springs' }}>
        <CommonHero
          pageTitle="Fall Roundup!"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1562725074/brianboals/downtown-cs.jpg"
        />

        <main className="bg-white p-relative">
          <section className="bg-light pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="fall-banner">
                    <Swiper {...slideshowParams}>
                      {banners.map((a, index) => {
                        return (
                          <div key={index} className="swiper-slide">
                            <div
                              style={{
                                backgroundImage: `url(https://res.cloudinary.com/brian-boals/image/upload/v1571217899/brianboals/${
                                  a.src
                                })`,
                              }}
                            />
                          </div>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-light py-5">
            <div className="container">
              {/* <div className="intro text-center mb-5">
                <h5 className="subtitle text-uppercase">Party</h5>
                <h2>Feedbacks</h2>
              </div> */}
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <Swiper {...feedbackParams}>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            We had a great time. We always enjoy the food, and I
                            think the boys enjoyed the mechanical bull this
                            year.
                          </p>
                          <h6 className="text-danger">
                            <em>—Andy—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            Thank you for inviting us to your harvest party! You
                            have wonderful house for it. And a beautiful view!
                            We had a good time! Thanks again!
                          </p>
                          <h6 className="text-danger">
                            <em>—Tony and Kris—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            You all out did yourselves this year…the food was
                            amazing as always for Shawn does the best BBQ
                            ever. All the crafts, face painting, balloons and
                            this year’s bouncy house were a definite hit with
                            the grandkids as well as the bull
                          </p>
                          <h6 className="text-danger">
                            <em>—Wanda—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            Hudson loved the mechanical bull.  I think he rode
                            it three times. Elijah loved the balloon guy and the
                            face painting. I loved the food. Thanks for having
                            us!
                          </p>
                          <h6 className="text-danger">
                            <em>—Roy—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            Thanks for setting up the Roundup again this year!
                            My favorite part is always BBQ. Evadel loved the
                            face painter, and she couldn&#39;t get enough of the
                            bull!
                          </p>
                          <h6 className="text-danger">
                            <em>—John—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            Easy...the square dancing and thank you!! We had a
                            great time, it was a gorgeous day!
                          </p>
                          <h6 className="text-danger">
                            <em>—Jessica—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            Hey Brian - Thanks for having us! Our favorite part
                            was meeting the other people that you have helped
                            find homes. We also enjoyed the food (Brooke loves
                            bundt cakes)!
                          </p>
                          <h6 className="text-danger">
                            <em>—Skyler—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            We enjoyed the short visit with you and Erica. Wish
                            it was longer but we had another appointment. The
                            food was very good, and the wasps agreed.
                          </p>
                          <h6 className="text-danger">
                            <em>—Richard—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            It was really happy and good vibes.  The Music
                            helped, I really liked the pork bbq.  I would say
                            just the atmosphere of everyone being happy brought
                            my spirits up.
                          </p>
                          <h6 className="text-danger">
                            <em>—Samuel—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="feedback">
                        <div className="feedback-body text-center">
                          <p className="font-weight-normal">
                            Just a great time! Thanks for inviting us. Our
                            niece, had a beautiful flower painted on her arm
                            that she will protect in the shower for years to
                            come. Patti enjoyed the BBQ and all the activities
                            for the kids. I, of course had my share of lunch and
                            some good healthy conversations. Thanks again!!!
                          </p>
                          <h6 className="text-danger">
                            <em>—Mike and Patti—</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default FallPage
